import { PlanWidgetRole } from '@wix/pricing-plans-common/blocks';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { makeUnselectable } from '../../blocks-widgets-editor/manifest-utils';
import { SINGLE_PLAN_HELP_ARTICLE_ID } from '../../constants';
import { getParentWidget } from '../../utils/widget';
import { openChangeRibbonTextPanel } from '../Plan/editor.controller';

export const getWidgetManifest: GetWidgetManifestFn = (builder, editorSDK, flowAPI) => {
  const t = flowAPI.translations.t;

  builder.gfpp().set('help', { id: SINGLE_PLAN_HELP_ARTICLE_ID });
  builder.configureConnectedComponents(PlanWidgetRole.RibbonContainer, makeUnselectable);

  builder.configureConnectedComponents(PlanWidgetRole.RibbonText, (ribbonTextBuilder) => {
    ribbonTextBuilder.set({ displayName: t('blocks.ribbon-design.text') });
    ribbonTextBuilder
      .behavior()
      .set({ closed: { hideFromHierarchy: false, selectable: true }, dataEditOptions: 'TEXT_STYLE_ONLY' });
    ribbonTextBuilder
      .gfpp()
      .set('mainAction2', {
        label: t('blocks.ribbon.edit-text'),
        onClick: async (e) =>
          openChangeRibbonTextPanel({
            editorSDK,
            componentRef: (await getParentWidget({ editorSDK, componentRef: e.detail.componentRef }))!,
            flowAPI,
          }),
      })
      .set('connect', { behavior: 'HIDE' });
  });
};
